import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("损益单号"),
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: T("盘点单号"),
    dataIndex: "stock_check_order_number",
    width: 160,
    ellipsis: true,
    scopedSlots: { customRender: "stock_check_order_number" },
  },
  {
    title: T("状态"),
    dataIndex: "is_completed",
    width: 160,
    ellipsis: true,
    customRender: (value) => (value ? "已完成" : "待处理"),
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 160,
    ellipsis: true,
  },
  {
    title: T("创建人"),
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 220,
    scopedSlots: { customRender: "action" },
  },
];
