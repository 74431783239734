<template>
  <div>
    <a-card :title="$t('盘点损益单')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('损益单号, 盘点单号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
          :scroll="{ x: 1020 }"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <template slot="stock_check_order_number" slot-scope="value, item, index">
            <a @click="detailStockCheckOrder(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
              <a-popconfirm :title="$t('确定保持不变吗?')" :disabled="item.is_completed" @confirm="remainsUnchanged(item)">
                <a-button type="primary" :loading="remainsUnchangedLoading" :disabled="item.is_completed">
                  {{ $t("保持不变") }}
                </a-button>
              </a-popconfirm>
              <a-popconfirm :title="$t('确定调整库存吗?')" :disabled="item.is_completed" @confirm="adjustingInventory(item)">
                <a-button type="primary" :loading="adjustingInventoryLoading" :disabled="item.is_completed">
                  {{ $t("调整库存") }}
                </a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { profitLossOrderList, profitLossOrderRemainsUnchanged, profitLossOrderAdjustingInventory } from "@/api/stockCheck";
import { columns } from "./columns";
import moment from "moment";
import { warehouseOption } from "@/api/option";

export default {
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, is_void: false },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],

      remainsUnchangedLoading: false,
      adjustingInventoryLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      profitLossOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    detail(item) {
      this.$router.push({ path: "/stock_check/profit_loss_order_detail", query: { id: item.id } });
    },
    detailStockCheckOrder(item) {
      this.$router.push({ path: "/stock_check/stock_check_order_detail", query: { id: item.stock_check_order } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    remainsUnchanged(item) {
      this.remainsUnchangedLoading = true;
      profitLossOrderRemainsUnchanged({ id: item.id })
        .then((data) => {
          this.$message.success(this.$t("保持不变成功"));
          this.items.splice(
            this.items.findIndex((i) => i.id == item.id),
            1,
            data
          );
        })
        .finally(() => {
          this.remainsUnchangedLoading = false;
        });
    },
    adjustingInventory(item) {
      this.adjustingInventoryLoading = true;
      profitLossOrderAdjustingInventory({ id: item.id })
        .then((data) => {
          this.$message.success(this.$t("调整库存成功"));
          this.items.splice(
            this.items.findIndex((i) => i.id == item.id),
            1,
            data
          );
        })
        .finally(() => {
          this.adjustingInventoryLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
